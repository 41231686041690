.d-none {
  display: none;
}

.d-flex {
  display: flex;
}

.row-reverse {
  flex-direction: row-reverse;
}

.column-reverse {
  flex-direction: column-reverse;
}

.flex-column {
  @extend .d-flex;
  flex-direction: column;
}

.flex-center {
  @extend .d-flex;
  justify-content: center;
  align-items: center;
}

.flex-between {
  @extend .d-flex;
  justify-content: space-between;
}

.align-start {
  align-items: flex-start;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.align-self-center {
  align-self: center;
}

.align-self-end {
  align-self: end;
}

.align-self-start {
  align-self: flex-start;
}


.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.justify-self-center {
  justify-self: center;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-grow-2 {
  flex-grow: 2;
}

.flex-grow-3 {
  flex-grow: 3;
}

.flex-wrap {
  flex-wrap: wrap;
}
