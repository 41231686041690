@use 'colors';
@use 'flexbox';
@use 'fonts';
@use 'inputs';
@use 'mixins';
@use '../assets/styles/schedule.min.css';
@use 'ejs-schedule-override.scss';
@use 'ejs-calendar-override.scss';

body {
  position: relative;
  font-family: 'Arial', serif;
  font-weight: 400;
  background-color: colors.$white;
  min-width: 320px;
  margin: 0;

  *:not(blockquote) {
    margin: 0;
    box-sizing: border-box;
    outline: none;
  }

  a {
    text-decoration: none;
  }

  img {
    user-select: none;
    object-fit: fill;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  div {
    // Firefox
    scrollbar-width: thin;
    scrollbar-color: #9fa0a4 #eeecec;

    // Chrome
    &::-webkit-scrollbar {
      width: 3px;
      height: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background: #d9d9d9; //NNG-857
      //border: 2px solid #9FA0A4;
    }

    &::-webkit-scrollbar-track {
      background: #eeecec;
    }
  }

  .w-fit-content {
    width: fit-content;
  }

  .w-auto {
    width: auto;
  }

  .w-100 {
    width: 100%;
  }

  .w-70 {
    width: 70%;
  }

  .w-75 {
    width: 75%;
  }

  .w-90 {
    width: 90%;
  }

  .w-10 {
    width: 10%;
  }

  .w-80 {
    width: 80%;
  }

  .w-85 {
    width: 85%;
  }

  .w-50 {
    width: 50%;
  }

  .w-34 {
    width: 34%;
  }

  .w-30 {
    width: 30%;
  }

  .w-24 {
    width: 24%;
  }

  .w-20 {
    width: 20%;
  }

  .w-60 {
    width: 60%;
  }

  .w-65 {
    width: 65%;
  }

  .w-40 {
    width: 40%;
  }

  .h-100 {
    height: 100%;
  }

  .h-85 {
    height: 85%;
  }

  .h-80 {
    height: 80%;
  }

  .h-60 {
    height: 60%;
  }

  .h-50 {
    height: 50%;
  }

  .w-150px {
    width: 150px;
  }

  .w-599px {
    width: 599px;
  }

  .h-249px {
    height: 249px;
  }

  .l-250px {
    left: 250px;
  }

  .t-28px {
    top: 28px;
  }

  .h-initial {
    height: initial;
  }

  .max-h-fit {
    max-height: fit-content;
  }

  .max-w-fit {
    max-width: fit-content;
  }

  .max-w-60 {
    max-width: 60%;
  }

  .max-w-50 {
    max-width: 50%;
  }

  .max-w-100 {
    max-width: 100%;
  }

  .max-w-82 {
    max-width: 83%;
    min-width: 82%;
    width: 83%;
  }

  .min-w-100 {
    min-width: 100%;
  }

  .min-w-80 {
    min-width: 80%;
  }

  .w-initial {
    width: initial !important;
  }

  .w-min-initial {
    min-width: initial;
  }

  .p-absolute {
    position: absolute;
  }

  .p-relative {
    position: relative;
  }

  .round-image {
    border-radius: 50%;
    width: 48px;
    height: 48px;
  }

  .separator {
    width: 100%;
    height: 1px;
    border-top: 1px solid colors.$dark-gray-300;
  }

  textarea {
    font-family: 'Arial', serif;
    resize: none;
  }

  .status-chip {
    padding: 4px 12px;
    background: colors.$light-green;
    border-radius: 500px;
    font-size: 12px;
    color: colors.$dark-green;
    width: fit-content;

    &:before {
      content: '';
      display: inline-block;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: colors.$dark-green;
      margin-right: 6px;
    }

    &.inactive {
      background: transparent;
      color: colors.$red;
      border: 1px solid colors.$red-opacity;

      &:before {
        background: colors.$red;
      }
    }
  }

  .c-pointer {
    cursor: pointer;
  }

  .c-default {
    cursor: default;
  }

  .gap-2 {
    gap: 2px;
  }

  .gap-8 {
    gap: 8px;
  }

  .gap-12 {
    gap: 12px;
  }

  .z-index-1 {
    z-index: 1;
  }

  .filter-state-wrapper {
    padding: 5px 8px;
    background: colors.$light-blue-20; //forum listing filter//
    border-radius: 2px;
    user-select: none;

    svg-icon {
      color: colors.$aqua-400; //forum listing filter selected items
    }

    &:hover {
      background: colors.$blue-20;

      svg-icon {
        color: colors.$blue-400;
      }
    }
  }

  .section-disabled {
    user-select: none;
    pointer-events: none;
    opacity: 0.5;
  }

  .z-index-2 {
    z-index: 2;
  }

  .z-index-3 {
    z-index: 3;
  }
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.pointer-events-none {
  pointer-events: none;
}

.opacity-50 {
  opacity: 0.5;
}

.highlight-item {
  position: relative;
  margin-left: 1%;

  &.green-bullets {
    &:before {
      top: 8px;
      background: colors.$dark-gray-515;
    }
  }

  &:before {
    content: '';
    background: colors.$blue-300;
    position: absolute;
    width: 4px;
    height: 4px;
    left: 0;
    top: 7px;
    border-radius: 2px;
  }

  &:not(:last-child) {
    margin-bottom: 6px;
  }
}

.save-item-icon {
  top: 12px;
  right: 12px;
  cursor: pointer;

  &.thin {
    path {
      stroke-width: 1px;
    }
  }

  //Zeigo Rebrand : Save Icon Changes on Different State
  &.saved {
    path {
      stroke: colors.$aqua-400;
      fill: colors.$aqua-400;
      fill-opacity: 1;
    }
  }

  &:not(.saved):hover {
    path {
      stroke: colors.$aqua-400;
    }
  }

  path {
    stroke: colors.$dark-gray-590;
    fill: transparent;
  }
}

.save-icon-corner {
  pointer-events: none;
  z-index: 9;
  position: absolute;
  right: 0;
  top: 0;
  width: 96px;
  height: 96px;
  //background: linear-gradient(225deg, rgba(11, 59, 107, 0.3) 0%, rgba(11, 59, 107, 0) 50%);
  background: linear-gradient(225deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 50%); //Forum in dashboard changes

  .favorite-icon {
    pointer-events: all;
    position: absolute;
    top: 12px;
    right: 12px;
    color: colors.$white;
    cursor: pointer;
    min-width: 22px;
    min-height: 22px;
    filter: drop-shadow(0 2px 3px rgba(0, 0, 0, 0.4));

    &.saved {
      path {
        fill: colors.$white;
        fill-opacity: 1;
      }
    }

    &:not(.saved):hover {
      path {
        fill: rgba(255, 255, 255, 0.75);
        fill-opacity: 0.75;
      }
    }

    path {
      stroke: colors.$white;
      fill: transparent;
    }
  }
}

.two-lines-ellipsis {
  @include mixins.text-lines-overflow(2);
}

.word-wrap {
  word-wrap: break-word;
}

.p-10 {
  padding: 0.5rem;
}

.fr {
  float: right;
}

.pl-35 {
  padding-left: 35%;
}

.event-edit-confirmation-modal {
  padding-left: 10px;

  .radio-controls-wrapper {
    label {
      &:before {
        border: solid 1px colors.$zeigo-purple !important;
      }
    }
  }
}

.text-subtitle {
  color: colors.$dark-gray-515;
}

.text-header {
  color: colors.$near-black-400;
}

.top-panel-btn-white {
  background: colors.$dark-gray-30;
  border-radius: 8px;
  width: auto;
  height: 47px;
  color: colors.$zeigo-purple;
  font-size: 14px;
  border: 1px solid colors.$dark-gray-30;
  line-height: 21px;

  &:hover {
    border-color: colors.$zeigo-purple;
    background-color: colors.$blue-15;
  }
}

.content {
  .filter-section {
    padding: 8px;
    gap: 12px;
    width: 284px;
    height: 48px;
    border: 1px solid colors.$violet-5;
  }
}

.text-near-white-color {
  color: colors.$blue-10;
}

.p-absolute-important {
  position: absolute !important;
}

.rotate-180 {
  transform: rotate(180deg);
  transition: all 0.2s ease;
}

.vertical-align-middle {
  vertical-align: middle;
}

.error-red-imp {
  color: colors.$red !important;
}

.response {
  color: colors.$purple-400;
}

.icon24 {
  font-size: 24px !important;
}

.w-fit {
  width: fit-content !important;
}

@media only screen and (max-width: 950px) {
  .c-pointer {
    cursor: none !important; //adding this code to global level as the sign up page, how did you hear dropdown is showing blue when disabled on selection in mobile
  }
}

.max-w-80 {
  max-width: 80%;
}

.max-w-30 {
  max-width: 30%;
}
.banner-create-account-link {
  color: colors.$dark-gray-30 !important;
  text-decoration: underline;
  font-family: 'Arial' !important;
}

.popup-controls {
  border-top: 1px solid colors.$violet-5;
  justify-content: flex-end;
  margin-top: 3%;
  padding-top: 3%;

  .btn-cancel,
  .btn-confirm {
    height: 42px;
    border-radius: 8px;
    border: none;
    color: colors.$dark-gray-590;
    transition: all 0.1s ease;
    width: 50%;
  }

  .btn-cancel {
    background: colors.$dark-gray-450;
    &:hover {
      background: colors.$dark-gray-250;
      border: none;
      color: colors.$dark-gray-590;
    }

    &:active {
      background: colors.$zeigo-bight-gray;
    }
  }

  .btn-confirm {
    padding: 12px 16px;
    background: colors.$zeigo-purple;
    font-weight: 400;
    font-size: 14px;
    color: colors.$dark-gray-40;

    &:hover {
      background: colors.$purple-400;
    }
  }
}
.initiative-counter {
  cursor: default;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  padding: 4px;
  color: colors.$white-30;
  background: var(--Semantic-Danger, colors.$red-100);
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  line-height: 13.2px;
  margin-bottom: 15px;
  font-family: 'Arial Rounded MT Bold';
  margin-left: 4%;
}
.initiative-counter-tooltip {
  position: relative;
  display: inline-block;
  margin-left: 10px;
  padding-top: 5px;
}

.initiative-counter-tooltip {
  position: relative;
  display: inline-block;
  margin-left: 4px;

  .tooltip-text {
    visibility: hidden;
    text-align: left;
    border-radius: 8px;
    font-family: 'Arial';
    line-height: 20px;
    left: 52%;
    background-color: colors.$near-black-600;
    color: colors.$white-10;
    padding: 5px 15px;
    font-size: small;
    position: absolute;
    z-index: 1;
    top: 33%;
    margin-left: 12px;
    font-weight: 700;
    inline-size: 178px;
    overflow: hidden;
    box-shadow: 0 2px 8px colors.$dark-gray-590;

    &::after {
      content: '';
      position: absolute;
      bottom: 100%;
      margin-left: -25px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent colors.$near-black-600 transparent;
    }
  }

  &:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
  }
}
.counter-p-4 {
  padding: 4px 4px;
}

.load-content-wrapper {
  background-color: colors.$purple-20;
  border-radius: 8px;
  border: 1px solid colors.$purple-160;
  min-height: 320px;
  align-items: center;
  display: flex;
  padding: 30px 0px 30px 0px;
}

.initiative-section-counter {
  top: -1.5em;
  right: -5px;
  position: relative;
  vertical-align: baseline;
  cursor: default;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  padding: 4px;
  color: colors.$white-30;
  background: var(--Semantic-Danger, colors.$red-100);
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  line-height: 13.2px;
  font-family: 'Arial Rounded MT Bold';
}

.public-title {
  margin-bottom: 8px;
  font-size: 22px;
  line-height: 36.4px;
}

.wrap-anywhere{
  overflow-wrap: anywhere;
}