.p-0 {
  padding: 0 !important;
}

.pr-0 {
  padding-right: 0;
}

.pb-0 {
  padding-bottom: 0;
}

.p-2 {
  padding: 2px;
}

.pt-2 {
  padding-top: 2px;
}

.pl-2 {
  padding-left: 2px;
}

.py-2 {
  padding-top: 2px;
  padding-bottom: 2px;
}

.p-4 {
  padding: 4px;
}

.pt-4 {
  padding-top: 4px;
}

.pb-4 {
  padding-bottom: 4px;
}

.pl-4 {
  padding-left: 4px;
}

.pr-4 {
  padding-right: 4px;
}

.p-6 {
  padding: 6px;
}

.pb-6 {
  padding-bottom: 6px;
}

.pt-6 {
  padding-top: 6px;
}

.pr-6 {
  padding-right: 6px;
}

.pl-6 {
  padding-left: 6px;
}

.p-8 {
  padding: 8px;
}

.pt-8 {
  padding-top: 8px;
}

.pl-8 {
  padding-left: 8px;
}

.pr-8 {
  padding-right: 8px;
}

.pb-8 {
  padding-bottom: 8px;
}

.px-8 {
  padding-left: 8px;
  padding-right: 8px;
}

.p-10 {
  padding: 10px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pl-10 {
  padding-left: 10px;
}

.pr-10 {
  padding-right: 10px;
}

.px-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.py-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.pt-0{
  padding-top: 0px;
}

.pt-10 {
  padding-top: 10px;
}

.p-12 {
  padding: 12px;
}

.pb-12 {
  padding-bottom: 12px;
}

.pt-12 {
  padding-top: 12px;
}

.pl-12 {
  padding-left: 12px;
}

.pr-12 {
  padding-right: 12px;
}

.pt-14 {
  padding-top: 14px;
}

.p-16 {
  padding: 16px;
}

.pt-16 {
  padding-top: 16px;
}

.pr-16 {
  padding-right: 16px;
}

.pl-16 {
  padding-left: 16px;
}

.pb-16 {
  padding-bottom: 16px;
}

.p-20 {
  padding: 20px;
}

.pt-20 {
  padding-top: 20px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pl-20 {
  padding-left: 20px;
}

.pr-20 {
  padding-right: 20px;
}

.p-24 {
  padding: 24px;
}

.pt-24 {
  padding-top: 24px;
}

.pl-24 {
  padding-left: 24px;
}

.pl-16-imp {
  padding-left: 16px !important;
}

.mt-6-imp {
  margin-top: 6px !important;
}


.pr-24 {
  padding-right: 24px;
}

.pb-24 {
  padding-bottom: 24px;
}

.px-24 {
  padding-left: 24px;
  padding-right: 24px;
}

.pt-26 {
  padding-top: 26px;
}

.pr-26 {
  padding-right: 26px;
}

.pb-26 {
  padding-bottom: 26px;
}

.p-28 {
  padding: 28px;
}

.pt-28 {
  padding-top: 28px;
}

.pb-28 {
  padding-bottom: 28px;
}

.pl-30 {
  padding-left: 30px;
}

.p-32 {
  padding: 32px;
}

.pl-32 {
  padding-left: 32px;
}

.pr-32 {
  padding-right: 32px;
}

.pt-32 {
  padding-top: 32px;
}

.pb-32 {
  padding-bottom: 32px;
}

.px-32 {
  padding-left: 32px;
  padding-right: 32px;
}

.pb-40 {
  padding-bottom: 40px;
}

.p-40 {
  padding: 40px;
}

.py-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.pt-40 {
  padding-top: 40px;
}

.p-40{
  padding: 40px;
}

.pl-40 {
  padding-left: 40px;
}

.pr-40 {
  padding-right: 40px;
}

.pr-44 {
  padding-right: 44px;
}

.pl-46 {
  padding-left: 46px;
}

.p-48 {
  padding: 48px;
}

.pl-48 {
  padding-left: 48px;
}

.pt-48 {
  padding-top: 48px;
}

.pb-48 {
  padding-bottom: 48px;
}

.pr-48 {
  padding-right: 48px;
}

.pl-54 {
  padding-left: 54px;
}

.p-56 {
  padding: 56px;
}

.pl-56 {
  padding-left: 56px;
}

.pt-56 {
  padding-top: 56px;
}

.pr-56 {
  padding-right: 56px;
}

.p-64 {
  padding: 64px;
}

.pt-64 {
  padding-top: 64px;
}

.pb-64 {
  padding-bottom: 64px;
}

.p-96 {
  padding: 96px;
}

.pr-96 {
  padding-right: 96px;
}

.pl-96 {
  padding-left: 96px;
}

.pb-96 {
  padding-bottom: 96px;
}

.pt-96 {
  padding-top: 96px;
}

.p-128 {
  padding: 128px;
}

.pl-128 {
  padding-left: 128px;
}

.p-160 {
  padding: 160px;
}

.pt-160 {
  padding-top: 160px;
}

// margin
.-mt-2 {
  margin-top: -2px;
}

.m-0 {
  margin: 0;
}

.m-8 {
  margin: 8px;
}

.mt-1 {
  margin-top: 1px;
}

.mt-3 {
  margin-top: 3px;
}

.mt-2 {
  margin-top: 2px;
}

.ml-2 {
  margin-left: 2px;
}

.ml-4 {
  margin-left: 4px;
}

.mr-4 {
  margin-right: 4px;
}

.mb-4 {
  margin-bottom: 4px;
}

.mt-4 {
  margin-top: 4px;
}

.mr-6 {
  margin-right: 6px;
}

.ml-6 {
  margin-left: 6px;
}

.mb-6 {
  margin-bottom: 6px;
}

.mt-6 {
  margin-top: 6px;
}

.mt-8 {
  margin-top: 8px;
}

.ml-8 {
  margin-left: 8px;
}

.mr-8 {
  margin-right: 8px;
}

.mb-8 {
  margin-bottom: 8px;
}

.ml-10 {
  margin-left: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mt-10 {
  margin-top: 10px;
}

.mr-12 {
  margin-right: 12px;
}

.ml-12 {
  margin-left: 12px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-14 {
  margin-top: 14px;
}

.mr-14 {
  margin-right: 14px;
}

.ml-14 {
  margin-left: 14px;
}

.mr-16 {
  margin-right: 16px;
}

.ml-16 {
  margin-left: 16px;
}

.mt-16 {
  margin-top: 16px;
}

.mb-16 {
  margin-bottom: 16px;
}

.ml-18 {
  margin-left: 18px;
}

.mb-18 {
  margin-bottom: 18px;
}

.mt-18 {
  margin-top: 18px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-22 {
  margin-top: 22px;
}


.ml-20 {
  margin-left: 20px;
}

.mr-20 {
  margin-right: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mr-24 {
  margin-right: 24px;
}

.ml-24 {
  margin-left: 24px;
}

.mt-24 {
  margin-top: 24px;
}

.mb-24 {
  margin-bottom: 24px;
}

.ml-26 {
  margin-left: 26px;
}

.mb-32 {
  margin-bottom: 32px;
}

.mr-32 {
  margin-right: 32px;
}

.ml-32 {
  margin-left: 32px;
}

.mt-32 {
  margin-top: 32px;
}

.mb-32 {
  margin-bottom: 32px;
}

.mt-32 {
  margin-top: 32px;
}

.mr-34 {
  margin-right: 34px;
}

.mb-34 {
  margin-bottom: 34px;
}

.ml-34 {
  margin-left: 34px;
}

.mt-34 {
  margin-top: 34px;
}

.mr-38 {
  margin-right: 38px;
}

.mb-38 {
  margin-bottom: 38px;
}

.ml-38 {
  margin-left: 38px;
}

.mt-38 {
  margin-top: 38px;
}

.mr-40 {
  margin-right: 40px;
}

.mt-40 {
  margin-top: 40px;
}

.mr-48 {
  margin-right: 48px;
}

.mb-48 {
  margin-bottom: 48px;
}

.mt-48 {
  margin-top: 48px;
}

.mt-50 {
  margin-top: 50px;
}

.ml-54 {
  margin-left: 54px;
}

.mr-56 {
  margin-right: 56px;
}

.mr-64 {
  margin-right: 64px;
}

.mr-90 {
  margin-right: 90px;
}

.ml-64 {
  margin-left: 64px;
}

.ml-90 {
  margin-left: 90px;
}

.mb-56 {
  margin-bottom: 56px;
}

.mb-64 {
  margin-bottom: 64px;
}

.mb-96 {
  margin-bottom: 96px;
}

// positioning {
.absolute {
  position: absolute;
}

.absolute-center-y {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

// top
.top-0 {
  top: 0;
}

.top-2 {
  top: 2px;
}

.top-8 {
  top: 8px;
}

.top-12 {
  top: 12px;
}

.top-16 {
  top: 16px;
}

.top-20 {
  top: 20px;
}

.top-40 {
  top: 40px;
}

// right
.right-0 {
  right: 0;
}

.right-8 {
  right: 8px;
}

.right-12 {
  right: 12px;
}

.right-16 {
  right: 16px;
}

.right-20 {
  right: 20px;
}

.right-24 {
  right: 24px;
}

.right-32 {
  right: 32px;
}

.right-40 {
  right: 40px;
}

.right-24-rem {
  right: 24rem;
}

// left
.left-0 {
  left: 0;
}

.left-16 {
  left: 16px;
}

.left-3-rem {
  left: 3.5rem;
}

.left-200 {
  left: 200px;
}

// bottom
.bottom-0 {
  bottom: 0;
}

.bottom-8 {
  bottom: 8px;
}

.bottom-24 {
  bottom: 24px;
}

.bottom-32 {
  bottom: 32px;
}

// gap
.gap-4 {
  gap: 4px;
}

.gap-6 {
  gap: 6px;
}

.gap-8 {
  gap: 8px;
}

.gap-12 {
  gap: 12px;
}

.gap-16 {
  gap: 16px;
}

.gap-24 {
  gap: 24px;
}

.gap-32 {
  gap: 32px;
}


.mb-8-imp {
  margin-bottom: 8px !important;
}

.mb-0-3-rem {
  margin-bottom: .3rem;
}

.ml-neg-0-4-rem {
  margin-left: -0.4rem;
}

.m-3-percent {
	margin: 3%;
}

.m-5-percent {
  margin: 5%;
}

.p-6-percent {
  padding: 3%;
}

.mr-0{
  margin-right: 0px;
}


.pt-0-imp {
  padding-top: 0 !important;
}

.pr-0-imp {
  padding-right: 0 !important;
}